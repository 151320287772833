import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@strategic/shared/api-service';
import { AppService } from '@strategic/shared/app-service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'strategic-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm!: FormGroup;
  private platform: any;
  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private cookieService: CookieService,
    private activateRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loginForm = this.appService.buildForm({
      name: '',
      phone: '',
      email: '',
      school: '',
      position: '',
    });
    this.appService.scrollToTop();
    this.activateRoute.queryParams.subscribe((res) => {
      this.platform = res;
    });
  }

  public handleSignup() {
    this.apiService
      .send('/api/tier/signup', this.loginForm.value, {
        xCookieId: this.cookieService.get('gem-visit') || 'generic-visitor',
      })
      .subscribe((res) => {
        if (res.msg) {
          this.loginForm.reset();
          this.snackBar.open('Signup successfully!!', 'Dismiss');
          if (this.platform?.app === 'ios') {
            if (window) {
              window.location.href = 'gusc://com.gem';
            }
          }
        }
      });
  }
}
