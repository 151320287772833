import { AfterViewInit, Component, OnInit } from '@angular/core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import Typed from 'typed.js';

@Component({
  selector: 'strategic-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss'],
})
export class WhatWeDoComponent implements OnInit, AfterViewInit {
  faArrowRight = faArrowRight;
  imgArr: string[] = [
    'https://strat-site.s3.us-east-2.amazonaws.com/finance-1.jpeg',
    'https://strat-site.s3.us-east-2.amazonaws.com/blk-women.jpeg',
    'https://strat-site.s3.us-east-2.amazonaws.com/school.jpeg',
  ];
  constructor(private router: Router) {
    //
  }

  ngOnInit(): void {
    this.startTyping();
  }

  ngAfterViewInit(): any {
    const typed = new Typed('#type', {
      strings: [
        'Board Function',
        'Fiscal Oversight',
        'Governance',
        'Strategy',
        'Reporting',
        'Meetings',
      ],
      startDelay: 300,
      typeSpeed: 200,
      loop: true,
      loopCount: Infinity,
    });

    return typed;
  }
  startTyping(): any {}

  handleClass(id: number): void {
    const dom: any = document.getElementById('what-img');
    dom.src = this.imgArr[id];
    dom.classList.add('what-img-animation');
  }

  handleRemove(): void {
    const dom: any = document.getElementById('what-img');
    dom.classList.remove('what-img-animation');
  }

  getPricing() {
    this.router.navigate(['/pricing']);
  }
}
