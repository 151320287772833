<div class="what-container">
  <div class="what-three-card-container">
    <video
      class="what-video"
      autoplay="true"
      [muted]="true"
      [loop]="true"
      src="https://strat-site.s3.us-east-2.amazonaws.com/strat-intro.mp4"
    ></video>
  </div>
  <div class="what-we-section">
    <h1 class="what-we-big-title">
      Let us help improve your <span class="title-typing" id="type"></span>
    </h1>
    <div class="what-we-section-container">
      <div class="what-we-left">
        <div class="what-we-finance">
          <h2 class="what-we-title">GEMpowerment™ Glossary</h2>
          <div class="what-we-description">
            The GEMpowerment Glossary is a robust collection of governance and
            educational terms, assisting boards to understand key oversight
            phrases and concepts.
          </div>
          <button (click)="getPricing()" class="what-we-btn">
            Get Started Now
          </button>
        </div>
        <div class="what-we-governance">
          <h2 class="what-we-title">Strategy Academy</h2>
          <div class="what-we-description">
            The Strategy Academy takes the mystery out of board roles and
            responsibilities and provides resources to empower your board’s
            operations. Through the GEM Guidebook, our library of short videos,
            cheat sheets and checklists, the Strategy Academy offers clear,
            concise explanations that are perfect if you are looking for an
            elementary introduction into topics or desire in-depth guidance.
            Whichever resource you choose, all will allow you to apply its
            knowledge.
          </div>
          <button (click)="getPricing()" class="what-we-btn">
            Get Started Now
          </button>
        </div>
        <div class="what-we-schools">
          <div class="we-are-img">
            <img
              src="https://strat-site.s3.us-east-2.amazonaws.com/img/black-girl.jpeg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="what-we-right">
        <div class="what-we-schools">
          <h2 class="what-we-title">Strategy Calendar</h2>
          <div class="what-we-description">
            The Strategy Calendar is an efficient way to ensure that your
            organization’s strategy remains at the forefront of all
            decision-making. This monthly planning tool offers guidance for
            boards by providing suggestions regarding data, discussions, and
            decisions that the board may want to consider as a part of that
            month’s oversight work. In addition to the calendar, this resource
            contains a prompt list of questions, able to empower members to ask
            the necessary governance questions.
          </div>
          <button (click)="getPricing()" class="what-we-btn">
            Get Started Now
          </button>
        </div>

        <div class="what-we-schools">
          <h2 class="what-we-title">Strategy Report</h2>
          <div class="what-we-description">
            The Strategy Report template is the perfect tool to provide your
            board with the visibility needed for effective governance and
            oversight. It empowers boards to review, track, and monitor
            essential operational and performance data, providing a thorough
            view of your organization’s position. Powered by governance best
            practices and KPIs, your board will have a regular review of the
            information that matters in fulfilling your oversight role.
          </div>
          <button (click)="getPricing()" class="what-we-btn">
            Get Started Now
          </button>
        </div>
        <div class="what-we-schools">
          <h2 class="what-we-title">Strategy Consulting Services</h2>
          <div class="what-we-description">
            Information is a key contributor to effective board decision making.
            While it is often assumed that board members have unlimited reserves
            of time and energy to execute great governance, this assumption is
            not consistent with the voluntary nature of most board positions.
            The reality is that board members are often accomplished
            professionals who have little time to design governance tools and
            techniques, but are compelled to ‘give back’ in meaningful service.
            For this reason, we offer consulting services in the areas of
            Finance, Governance, Board Development, and Strategic Planning. Our
            experience and expertise lead directors to major in governance
            instead of minoring in management and operations. Allow our teams to
            energize your board by delivering support solutions that afford you
            to serve with assurance and ease. Purchased individually or bundled
            together to create a suite of skills, our clients have found the
            ability to secure support at various budgetary levels.
          </div>
          <div class="what-we-description-b">
            Let us empower your Board for improved governance and oversight
            function.
          </div>
          <!-- <button (click)="getPricing()" class="what-we-btn">
            Get More Information
          </button> -->
        </div>
      </div>
    </div>
  </div>
</div>
