<div class="who-container">
  <div class="who-we-are-jumbo">
    <div class="we-are">We Are Strategic Boards</div>
    <div class="we-are-mission">
      A team of forward thinkers who have been providing over 16 years of
      expertise in this industry. We believe in operating in integrity and
      excellence while empowering our boards. We understand the importance of
      good governance, strategic planning and continuous growth, therefore, we
      are able to offer our credentials with proven processes to equip our
      Boards for success.
    </div>
  </div>
</div>
