import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';

@Component({
  selector: 'strategic-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {
  public color = 'accent';
  spinner;
  constructor(private router: Router, private ngxService: NgxUiLoaderService) {
    this.spinner = SPINNER.threeStrings;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.ngxService.start();
      }

      if (event instanceof NavigationEnd) {
        this.ngxService.stop();
      }
    });
  }

  ngOnInit(): void {}
}
