import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) {
    //
  }

  public retrieve(url: string, additionalHeaders?: any): Observable<any> {
    console.log('ApiService -> Retrieve call');
    return this.httpClient.get(url, {
      headers: { ...additionalHeaders },
    });
  }

  public send(
    url: string,
    payload: any,
    additionalHeaders?: any
  ): Observable<any> {
    return this.httpClient.post(url, payload, {
      ...additionalHeaders,
    });
  }

  public update() {
    console.log('update a request');
  }

  public remove() {
    console.log('remove a request');
  }
}
