<div class="pricing">
  <div class="pricing__banner">
    <div class="pricing__banner_center">
      <h1 class="pricing__banner_center_info">
        Get Started Now, Pick a Plan Later
      </h1>
      <h2 class="pricing__banner_center_sub-info">
        Get started now to receive free, unrestricted access to the
        GEMpowerment™ Glossary.
      </h2>
      <h5 class="pricing__banner_center_sub-info-2">
        Download in your app store today!
      </h5>
      <div class="pricing__banner_center_btn">
        <span
          id="annual"
          #annual
          (click)="handleSubscription(annual.id)"
          class="pricing__banner_center_btn_annual"
          >Annually</span
        >
        <!-- <span
          id="monthly"
          #monthly
          (click)="handleSubscription(monthly.id)"
          class="pricing__banner_center_btn_monthly"
          >Monthly</span
        > -->
      </div>
    </div>
  </div>
  <div class="pricing__tier">
    <div
      *ngFor="let tier of pageData?.tiers"
      [class]="getCardClass(tier.cardClass)"
    >
      <div class="pricing__tier_section-title">
        <h1>{{ tier.title }}</h1>
        <span class="pricing__tier_section-title_sub">{{ tier.sub }}</span>
        <button class="pricing__tier_section-title_btn">
          {{ tier.btnText }}
        </button>
      </div>
      <div class="pricing__tier_section-price">
        <!-- <h1>
          {{ tier.price
          }}<span class="pricing__tier_section-price_mo">/mo</span>
        </h1> -->
        <div
          class="pricing__tier_section-price_included"
          *ngFor="let included of tier.included; index as i"
        >
          <fa-icon
            class="pricing__tier_section-price_included_facon"
            [icon]="faCircleCheck"
          ></fa-icon
          >{{ included }}
          <button
            *ngIf="tier.showTooltip"
            mat-raised-button
            [matTooltip]="tier.tooltip[i]"
            matTooltipClass="example-tooltip-red"
            aria-label="Button that shows a red tooltip"
            class="pricing__tier_section-price_included_btn"
          >
            <fa-icon
              class="pricing__tier_section-price_included_tooltip"
              [icon]="faQuestionCircle"
            ></fa-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="pricing__motivation">
    <div class="pricing__motivation_why">
      <h1>17 years in business and counting...</h1>
      <span class="pricing__motivation_why_trust">Certified by:</span>
      <div class="pricing__motivation_why_trust-us">
        <div
          *ngFor="let workWith of pageData?.credentials"
          class="pricing__motivation_why_trust-us-img"
        >
          <img [src]="workWith" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
