import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const navAnimations = {
  animations: [
    trigger('slideNav', [
      state(
        'in',
        style({ display: 'none', opacity: 0, transform: 'translateX(-200px)' })
      ),
      state('out', style({ opacity: 1, transform: 'translateX(0px)' })),
      transition('out=>in', [
        animate(
          '300ms cubic-bezier(0.785, 0.135, 0.15, 0.86)',
          style({
            transform: 'translateX(0px)',
            opacity: 0,
          })
        ),
      ]),
      transition('in=>out', [
        animate(
          '200ms cubic-bezier(0.785, 0.135, 0.15, 0.86)',
          style({
            transform: 'translateX(-200px)',
            opacity: 1,
          })
        ),
      ]),
    ]),
  ],
};
