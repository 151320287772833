<div class="overview-container">
  <h1 class="title">George Washington Carver</h1>
  <mat-tab-group mat-align-tabs="center" class="dashboard">
    <mat-tab class="tile-label">
      <ng-template mat-tab-label>
        <div class="tile-label">
          <fa-icon class="facon" [icon]="faChartLine"></fa-icon>
          Overview
        </div>
      </ng-template>
      <div class="content-overview">
        <div class="overview-card">
          <mat-card class="tile-card">
            <!-- Bar Chart-->
            <div class="flex">
              <div class="flex-item">
                <div style="display: block">
                  <canvas
                    baseChart
                    width="400"
                    height="400"
                    [data]="lineChartData"
                    [options]="lineChartOptions"
                    [type]="lineChartType"
                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"
                  ></canvas>
                </div>
              </div>
              <div class="flex-item">
                <table class="table table-responsive table-condensed">
                  <tr>
                    <th *ngFor="let label of lineChartData.labels">
                      {{ label }}
                    </th>
                  </tr>
                  <tr
                    *ngFor="let d of lineChartData.datasets; let i = index"
                    [class]="'line-' + i"
                  >
                    <td
                      *ngFor="let label of lineChartData.labels; let j = index"
                    >
                      {{ d && d.data[j] }}
                    </td>
                  </tr>
                </table>
                <div class="button-row">
                  <button mat-button mat-raised-button (click)="randomize()">
                    Randomize
                  </button>
                  <button mat-button mat-raised-button (click)="pushOne()">
                    Push
                  </button>
                  <button mat-button mat-raised-button (click)="changeColor()">
                    Recolor
                  </button>
                  <button mat-button mat-raised-button (click)="hideOne()">
                    Toggle Series B
                  </button>
                  <button mat-button mat-raised-button (click)="changeLabel()">
                    Change Label
                  </button>
                </div>
              </div>
            </div>
          </mat-card>
          <mat-card class="tile-card">
            <!-- Bar Chart-->
            <div class="flex">
              <div class="flex-item">
                <div style="display: block">
                  <canvas
                    baseChart
                    width="400"
                    height="400"
                    [data]="lineChartData"
                    [options]="lineChartOptions"
                    [type]="lineChartType"
                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"
                  ></canvas>
                </div>
              </div>
              <div class="flex-item">
                <table class="table table-responsive table-condensed">
                  <tr>
                    <th *ngFor="let label of lineChartData.labels">
                      {{ label }}
                    </th>
                  </tr>
                  <tr
                    *ngFor="let d of lineChartData.datasets; let i = index"
                    [class]="'line-' + i"
                  >
                    <td
                      *ngFor="let label of lineChartData.labels; let j = index"
                    >
                      {{ d && d.data[j] }}
                    </td>
                  </tr>
                </table>
                <div class="button-row">
                  <button mat-button mat-raised-button (click)="randomize()">
                    Randomize
                  </button>
                  <button mat-button mat-raised-button (click)="pushOne()">
                    Push
                  </button>
                  <button mat-button mat-raised-button (click)="changeColor()">
                    Recolor
                  </button>
                  <button mat-button mat-raised-button (click)="hideOne()">
                    Toggle Series B
                  </button>
                  <button mat-button mat-raised-button (click)="changeLabel()">
                    Change Label
                  </button>
                </div>
              </div>
            </div>
          </mat-card>
          <mat-card class="tile-card">
            <!-- Bar Chart-->
            <div class="flex">
              <div class="flex-item">
                <div style="display: block">
                  <canvas
                    baseChart
                    width="400"
                    height="400"
                    [data]="lineChartData"
                    [options]="lineChartOptions"
                    [type]="lineChartType"
                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"
                  ></canvas>
                </div>
              </div>
              <div class="flex-item">
                <table class="table table-responsive table-condensed">
                  <tr>
                    <th *ngFor="let label of lineChartData.labels">
                      {{ label }}
                    </th>
                  </tr>
                  <tr
                    *ngFor="let d of lineChartData.datasets; let i = index"
                    [class]="'line-' + i"
                  >
                    <td
                      *ngFor="let label of lineChartData.labels; let j = index"
                    >
                      {{ d && d.data[j] }}
                    </td>
                  </tr>
                </table>
                <div class="button-row">
                  <button mat-button mat-raised-button (click)="randomize()">
                    Randomize
                  </button>
                  <button mat-button mat-raised-button (click)="pushOne()">
                    Push
                  </button>
                  <button mat-button mat-raised-button (click)="changeColor()">
                    Recolor
                  </button>
                  <button mat-button mat-raised-button (click)="hideOne()">
                    Toggle Series B
                  </button>
                  <button mat-button mat-raised-button (click)="changeLabel()">
                    Change Label
                  </button>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="overview-card">
          <mat-card class="tile-card-long">
            <div>
              <div style="display: block">
                <canvas
                  baseChart
                  [data]="barChartData"
                  [options]="barChartOptions"
                  [plugins]="barChartPlugins"
                  [type]="barChartType"
                  (chartHover)="chartHov($event)"
                  (chartClick)="chartClick($event)"
                >
                </canvas>
              </div>
              <button mat-button mat-raised-button (click)="rando()">
                Update
              </button>
            </div>
          </mat-card>
        </div>

        <div class="overview-card">
          <mat-card class="tile-card-long">
            <div>
              <div>
                <div>
                  <div style="display: block">
                    <canvas
                      baseChart
                      [data]="barChartData"
                      [options]="barChartOptions"
                      [type]="barChartType"
                      (chartHover)="chartHovered($event)"
                      (chartClick)="chartClicked($event)"
                    ></canvas>
                  </div>
                  <button mat-button mat-raised-button (click)="randomize()">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tile-label">
          <fa-icon class="facon" [icon]="faMoneyCheckDollar"></fa-icon>
          Budget Report
        </div>
      </ng-template>
      <div class="content-overview">
        <div class="overview-card">
          <mat-card class="tile-card-long">
            <div>
              <div>
                <div class="chart">
                  <canvas
                    baseChart
                    [data]="pieChartData"
                    [type]="pieChartType"
                    [options]="pieChartOptions"
                    [plugins]="pieChartPlugins"
                  >
                  </canvas>
                </div>
              </div>
            </div>
            <div class="button-row">
              <button
                mat-button
                mat-raised-button
                color="primary"
                (click)="toggleLegend()"
              >
                Toggle Legend
              </button>
              <button
                mat-button
                mat-raised-button
                color="primary"
                (click)="changeLabels()"
              >
                Change Labels
              </button>
              <button
                mat-button
                mat-raised-button
                color="primary"
                (click)="addSlice()"
              >
                Add Slice
              </button>
              <button
                mat-button
                mat-raised-button
                color="primary"
                (click)="removeSlice()"
              >
                Remove Slice
              </button>
              <button
                mat-button
                mat-raised-button
                color="primary"
                (click)="changeLegendPosition()"
              >
                Change Legend Position
              </button>
            </div>
          </mat-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tile-label">
          <fa-icon class="facon" [icon]="faDollarSign"></fa-icon>
          Cashflow
        </div>
      </ng-template>

      <div class="content-overview">
        <div class="overview-card">
          <mat-card class="tile-card-long"
            ><div class="tile-title">Overall</div>
            <div class="tile-score"></div>
          </mat-card>
        </div>

        <div class="overview-card">
          <mat-card class="tile-card-long"
            ><div class="tile-title">Overall</div>
            <div class="tile-score"></div>
          </mat-card>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
