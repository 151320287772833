<strategic-navigation></strategic-navigation>
<div class="container">
  <ngx-ui-loader
    pbColor="#ffd166"
    fgsColor="#ffd166"
    [fgsType]="spinner"
  ></ngx-ui-loader>
  <strategic-sidenav></strategic-sidenav>
  <router-outlet></router-outlet>
</div>
<strategic-footer></strategic-footer>
