import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@strategic/shared/api-service';
import { AppService } from '@strategic/shared/app-service';
import { CookieService } from 'ngx-cookie-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'strategic-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public pageData: any;
  public count = 0;
  public cookie: any;
  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.cookie = cookieService.get('gem-visit');
  }

  ngOnInit(): void {
    this.ngxService.start();
    this.apiService
      .retrieve('/api/home', {
        xCookieId: this.cookie || 'generic-visitor',
      })
      .subscribe((res) => {
        if (res) {
          console.log('res', res);
          this.pageData = res.data;
          this.ngxService.stop();
        } else {
          console.log('Failed to retrieve data', res);
        }
      });
    this.appService.scrollToTop();
    this.count++;
    this.cookieService.set('gem-visit', `gem${this.count}`);
  }

  getStarted() {
    this.router.navigate(['/signup']);
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }
}
