/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, OnInit } from '@angular/core';
import {
  faCircleCheck,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AppService } from '@strategic/shared/app-service';
import { ApiService } from '@strategic/shared/api-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'strategic-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  faCircleCheck = faCircleCheck as IconProp;
  faQuestionCircle = faQuestionCircle as IconProp;
  pageData: any;

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.ngxService.start();
    this.apiService.retrieve('/api/pricing').subscribe((res) => {
      this.pageData = res.data;
      this.ngxService.stop();
    });
    this.appService.scrollToTop();
  }

  handleSubscription(id: string) {
    if (id == 'annual') {
      document.getElementById(id)!.style.backgroundColor = '#113868';
      document.getElementById('monthly')!.style.backgroundColor = 'transparent';
    } else {
      document.getElementById(id)!.style.backgroundColor = '#113868';
      document.getElementById('annual')!.style.backgroundColor = 'transparent';
    }
  }

  getCardClass(cl: string) {
    return `pricing__tier_${cl}`;
  }
}

interface Tier {
  title: string;
  cardClass: string;
  sub: string;
  btnText: string;
  price: string;
  showTooltip: boolean;
  included: string[];
}
