<div class="home-container">
  <div class="home-section">
    <div class="home-left-side">
      <div class="home-left-side-img">
        <img
        src="https://strat-site.s3.us-east-2.amazonaws.com/GEMpowerment.png"
        alt=""
      />
      </div>
      <h1 class="home-info">
        We believe in Strategic Boards who govern schools with a strategic
        mindset. <i>This is Good Governance.</i>
      </h1>
      <h2 class="home-sub-info">
        A strategic, systems thinking, governance mindset, supported by the
        right operational framework, can be the solution your team seeks to lead
        the oversight work of your board. Allow the GEMpowerment Model&#174; to
        be your board's guide, empowering your efforts to be truly strategic.
      </h2>
      <div (click)="getStarted()" class="home-btn">Get Started Today!!!</div>
    </div>
    <div class="home-right-side">
      <img
        src="https://strat-site.s3.us-east-2.amazonaws.com/img/strat-img.jpeg"
      />
    </div>
  </div>
  <div class="home-strat-offering">
    <div class="strat-fin">
      <i class="fas fa-money-bill"></i>
      Finance consulting to encourage fiduciary responsibility and good
      financial stewardship while aligning finite school resources and
      prioritized strategic goals.
    </div>
    <div class="strat-gov">
      <i class="fas fa-gavel"></i>
      Governance Consulting & Leadership Development to assist your team to
      embrace and exemplify best practices of 21st Century leaders
    </div>
    <div class="strat-school">
      <i class="fas fa-building"></i>
      GEM® APP to energize your board with an operational framework able to
      replicate success in the domains of purpose, people, planning, and in your
      processes and performance
    </div>
  </div>

  <div class="who-loves-us">
    <h2 class="see-why">See why our customers love us</h2>
    <div class="loves-us-container">
      <div *ngFor="let page of pageData?.seeWhyVideos" class="vid-1">
        <div class="vid-overlay">
          {{ page?.title }} -
          <i>"{{ page?.content }}"</i>
        </div>

        <video
          autoplay="true"
          [muted]="true"
          [loop]="true"
          [src]="page?.vid"
        ></video>
      </div>
    </div>
  </div>

  <div class="why-they-love-us">
    <span class="trust-why">Professional Credentials</span>
    <div class="trust-us-container">
      <div *ngFor="let credImg of pageData?.credentials" class="trust-us">
        <img [src]="credImg" alt="" />
      </div>
    </div>
  </div>
  <div class="bottom-section">
    <div class="bottom-section_img-container">
      <img
        src="https://strat-site.s3.us-east-2.amazonaws.com/GEMpowerment.png"
        alt=""
      />
    </div>
    <div class="bottom-title">LEVERAGE THE ADVANTAGE OF GUIDED GOVERNANCE</div>
    <div class="bottom-content">
      <div class="bottom-left">
        Every board desires to govern well and to drive mission success.
        Alongside this desire, lives the reality of challenges that threaten the
        efficiency of board work and culture. Challenges may come in varying
        types and categories, but they all require the same thing: direction and
        decision. Education, information, operational process, and mindset are
        key contributors to strategic decision making. While it is often assumed
        that members have unlimited reserves of time and energy to execute great
        governance, this assumption is not consistent with the nature of board
        service. The reality is that members are often accomplished
        professionals who have a limited amount of time to perfect and deploy
        board duties.
      </div>
      <div class="bottom-right">
        For this reason, the GEMpowerment Model® contained in the GEM® App
        harnesses knowledge of the skills and expertise needed to empower
        governance success, placing guided governance right in your hands. Our
        experience and expertise leads directors to major in true strategic
        oversight instead of minoring in operational and tactical matters. Allow
        the GEM® App to energize your board by rendering an operational
        framework solution that affords your team to serve with assurance and
        ease.
      </div>
    </div>
    <div class="bottom-video">
      <video
        autoplay="true"
        [muted]="true"
        controls
        [loop]="true"
        [src]="pageData?.stratVid"
      ></video>
    </div>
  </div>
  <div class="bottom-section-2">
    <div class="card-grid-container">
      <h2 class="see-why-2">CHOOSE A PLAN THAT MATCHES YOUR NEED</h2>
      <div class="container">
        <div class="cardWrap">
          <div class="card">
            <div class="cardBg1"></div>
            <div class="cardInfo">
              <h3 class="cardTitle">GEMpowerment™ Glossary</h3>
              <p>
                <a href="#strat-app"
                  >Download the free app from your app store.</a
                >
              </p>
            </div>
          </div>
        </div>
        <div (click)="navigateTo('/pricing')" class="cardWrap">
          <div class="card">
            <div class="cardBg2"></div>
            <div class="cardInfo">
              <h3 class="cardTitle">Strategy Academy</h3>
              <!-- <p>Lorem Ipsum is simply dummy text</p> -->
            </div>
          </div>
        </div>
        <div (click)="navigateTo('/pricing')" class="cardWrap">
          <div class="card">
            <div class="cardBg3"></div>
            <div class="cardInfo">
              <h3 class="cardTitle">Strategy Calendar</h3>
              <!-- <p>Lorem Ipsum is simply dummy text</p> -->
            </div>
          </div>
        </div>
        <div (click)="navigateTo('/pricing')" class="cardWrap">
          <div class="card">
            <div class="cardBg4"></div>
            <div class="cardInfo">
              <h3 class="cardTitle">Strategy Report</h3>
              <!-- <p>Lorem Ipsum is simply dummy text</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div (click)="getStarted()" class="big-btn">GET STARTED NOW</div>
  </div>
</div>
