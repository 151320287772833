<div class="footer-container">
  <div class="footer-middle">
    <div class="left-links">
      <a [routerLink]="['/']">
        <fa-icon class="facon" [icon]="faHome"></fa-icon>
        Home
      </a>
      <a [routerLink]="['/who-we-are']">
        <fa-icon class="facon" [icon]="faBuilding"></fa-icon>
        Who We Are
      </a>
      <a [routerLink]="['/what-we-do']">
        <fa-icon class="facon" [icon]="faChartLine"></fa-icon>
        What We Offer
      </a>

      <!-- <a [routerLink]="['/']">
        <i class="fas fa-user footer-icon"></i>
        Who we are
      </a>
      <a [routerLink]="['/']">
        <i class="fa fa-line-chart footer-icon"></i>
        What we do
      </a> -->
    </div>

    <div class="middle-links">
      <a
        id="strat-app"
        href="https://apps.apple.com/us/app/gem-strategic-boards/id1634513786"
      >
        <div class="img-icon-container">
          <img src="../../../assets/img/apple-icon.png" alt="" />
        </div>

        Download Our IOS App
      </a>
      <a
        id="strat-app"
        href="https://play.google.com/store/apps/details?id=com.strategicboards.gem"
      >
        <div class="img-icon-container">
          <img src="../../../assets/img/android-icon.png" alt="" />
        </div>
        <!-- <fa-icon class="facon" [icon]="faMobileAndroid"></fa-icon> -->
        Download Our Android App
      </a>
      <a [routerLink]="['/privacy']">
        <fa-icon class="facon" [icon]="faLock"></fa-icon>
        Privacy
      </a>
    </div>
    <div class="right-links">
      <a [routerLink]="['/resources']">
        <fa-icon class="facon" [icon]="faInfoCircle"></fa-icon>
        Resources
      </a>
      <a [routerLink]="['/pricing']">
        <fa-icon class="facon" [icon]="faDollarSign"></fa-icon>
        Pricing
      </a>
      <a [routerLink]="['/signup']">
        <i class="fa fa-address-card footer-icon"></i>
        Signup
      </a>
      <!-- <a href="https://twitter.com/elynnjac">
        <i class="fab fa-twitter-square footer-icon"></i>
        Twitter
      </a>
      <a href="https://www.instagram.com/estherljackson/">
        <i class="fab fa-instagram-square footer-icon"></i>
        Provision
      </a> -->
    </div>
  </div>
  <span class="footer-name">Strategic Boards &copy; 2022 </span>
  <span class="powered-by-name">Powered By: CMJ Software </span>
</div>
