<div class="resource">
  <div class="resource__lft">
    <div class="resource__lft_intro">
      <div class="resource__lft_intro_txt">
        Strategic boards require key governance resources.
      </div>
      <div class="resource__lft_intro_sub-txt">
        Spend less time feeling overwhelmed and more time empowered by guided
        governance. Stay in the know-subscribe now to receive a free monthly
        GEMpowerment™ resource.
      </div>

      <form [formGroup]="resourcesForm" class="resource__lft_intro_input">
        <div class="resource__lft_intro_input_container">
          <label for="name">Full Name</label>
          <input [formControlName]="'name'" type="text" />
        </div>
        <div class="resource__lft_intro_input_container">
          <label for="email">E-mail address</label>
          <input [formControlName]="'email'" type="email" />
        </div>
        <div
          (click)="handleSubscription()"
          class="resource__lft_intro_input_btn"
        >
          Subscribe
        </div>
      </form>
    </div>
  </div>

  <div class="resource__rht">
    <img
      class="resource__rht_img"
      src="https://strat-site.s3.us-east-2.amazonaws.com/img/resource-img-banner.png"
      alt=""
    />
  </div>
</div>
