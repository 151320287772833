/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public isLoading$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public isLoggedIn$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public toggleNav$: BehaviorSubject<any> = new BehaviorSubject<any>('in');
  public user$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public formBuilder: FormBuilder = new FormBuilder();

  constructor() {
    //
  }

  scrollToTop() {
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  public buildForm(fields: any): FormGroup {
    const form: any = {};

    Object.keys(fields).map((key) => {
      form[key] = this.formBuilder.control(fields[key], [], []);
    });

    return this.formBuilder.group(form);
  }
}
