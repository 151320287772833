import { Component, OnInit } from '@angular/core';
import { navAnimations } from './side-nav.animation';
import { Router } from '@angular/router';
import { AppService } from '@strategic/shared/app-service';
import {
  faChartLine,
  faScaleBalanced,
  faHands,
  faMoneyCheckDollar,
  faDollarSign,
  faRectangleList,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  animations: navAnimations.animations,
  selector: 'strategic-sidenav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  isLoggedIn = false;
  toggleNav = true;
  slideOut = true;

  routeForSignedInUser = '';

  faHands = faHands as IconProp;
  faChartLine = faChartLine as IconProp;
  faRectangleList = faRectangleList as IconProp;
  faScaleBalanced = faScaleBalanced as IconProp;
  faMoneyCheckDollar = faMoneyCheckDollar as IconProp;
  faDollarSign = faDollarSign as IconProp;

  sites = {
    'sites/ddt': {
      name: 'hazel',
      site: 'DDT1 - Hazel Park, MI',
    },
    'sites/ddt2': {
      name: 'romulus',
      site: 'DDT2 - Romulus, MI',
    },
    'sites/dcn': {
      name: 'ohio',
      site: 'DCN1 - Cincinnati, OH',
    },
    'sites/dgr': {
      name: 'rapids',
      site: 'DGR1 - Grand Rapids, MI',
    },
    'sites/dms': {
      name: 'eagan',
      site: 'DMS1 - Eagan, MN',
    },
    'sites/dbm': {
      name: 'mobile',
      site: 'DBM3 - Mobile, AL',
    },
    'sites/hdt': {
      name: 'bulky',
      site: 'HDT2 - Heavy Bulky',
    },
  };

  constructor(private router: Router, private appService: AppService) {}

  ngOnInit(): void {
    this.appService.toggleNav$.subscribe((toggle) => {
      this.toggleNav = toggle;
    });
  }

  navigateTo(route: string) {
    this.appService.toggleNav$.next('in');
    this.router.navigate([route]);
  }
}
