<div class="login">
  <div class="login__form">
    <div class="login__form_card">
      <div class="login__form_card_title">
        <h1>Contact Us</h1>
        <span class="login__form_card_title_gry">
          Please enter your details.</span
        >
      </div>
      <form [formGroup]="loginForm" class="login__form_card_fields">
        <div class="login__form_card_fields_email">
          <label for="name">Full Name</label>
          <input
            [formControlName]="'name'"
            type="text"
            placeholder="Enter Full name"
          />
        </div>
        <div class="login__form_card_fields_pass">
          <label for="phone">Phone</label>
          <input
            [formControlName]="'phone'"
            type="text"
            placeholder="Ex: (313)-777-9311"
          />
        </div>

        <div class="login__form_card_fields_email">
          <label for="email">Email</label>
          <input
            type="email"
            [formControlName]="'email'"
            type="text"
            placeholder="Ex: example@gmail.com"
          />
        </div>
        <div class="login__form_card_fields_pass">
          <label for="school">School/Organization</label>
          <input
            [formControlName]="'school'"
            type="text"
            placeholder="Ex: School or Origanization"
          />
        </div>

        <div class="login__form_card_fields_pass">
          <label for="position">Position</label>
          <input
            [formControlName]="'position'"
            type="text"
            placeholder="Ex: Director"
          />
        </div>

        <button (click)="handleSignup()" class="login__form_card_fields_btn">
          Sign Up
        </button>
        <!-- <div class="login__form_card_fields_sign-up">
          Already have an account?
          <span class="login__form_card_fields_sign-up-info"></span>
        </div> -->
      </form>
    </div>
    <div class="login-img-container">
      <div class="login-overlay"></div>
      <div class="login-info-container">
        <div class="login-blur-card"></div>
        <div class="login-txt">
          "A team of forward thinkers who have been providing over 16 years of
          expertise in this industry "
        </div>
      </div>
    </div>
  </div>
</div>
