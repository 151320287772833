<div [@slideNav]="toggleNav" class="sidenav-container">
  <div class="sidenav-title-container">
    <a
      *ngIf="isLoggedIn"
      (click)="navigateTo('dashboard')"
      class="sub-title-link"
    >
      <i class="fas fa-chart-line"></i>
      Pages
    </a>
  </div>

  <div class="sidenav-sub-title">
    <div class="sub-title">Pages</div>
    <div (click)="navigateTo('/')" class="sub-title-link">
      <i class="fas fa-home nav-icon"></i>
      Home
    </div>
    <div (click)="navigateTo('who-we-are')" class="sub-title-link">
      <fa-icon class="facon" [icon]="faChartLine"></fa-icon>
      Who We Are
    </div>
    <div (click)="navigateTo('what-we-do')" class="sub-title-link">
      <fa-icon class="facon" [icon]="faRectangleList"></fa-icon>
      What We Offer
    </div>
    <div (click)="navigateTo('resources')" class="sub-title-link">
      <fa-icon class="facon" [icon]="faScaleBalanced"></fa-icon>
      Resources
    </div>
    <div (click)="navigateTo('pricing')" class="sub-title-link">
      <fa-icon class="facon" [icon]="faMoneyCheckDollar"></fa-icon>
      Pricing
    </div>

  </div>
</div>
