<div class="nav-container">
  <div (click)="navigateTo('/')" class="navs-icon">
    <img
      src="https://strat-site.s3.us-east-2.amazonaws.com/img/logo/Strategic-Boards.png"
    />
  </div>

  <div class="navs-links">
    <div (click)="navigateTo('who-we-are')">Who We Are</div>
    <div (click)="navigateTo('what-we-do')">What We Offer</div>
    <div (click)="navigateTo('resources')">Resources</div>
    <div (click)="navigateTo('pricing')">Pricing</div>
    <div (click)="navigateTo('contact-us')" class="login-btn">Contact Us</div>
  </div>

  <div class="navs-btns">
    <div (click)="toggleNav()" class="menu-icon">
      <i class="fas fa-bars nav-icon"></i>
    </div>
  </div>
</div>
