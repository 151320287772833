import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@strategic/shared/api-service';
import { AppService } from '@strategic/shared/app-service';

@Component({
  selector: 'strategic-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit {
  public resourcesForm!: FormGroup;

  constructor(
    private appService: AppService,
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.resourcesForm = this.appService.buildForm({
      name: '',
      email: '',
    });
    this.appService.scrollToTop();
  }

  public handleSubscription() {
    this.apiService
      .send('/api/tier/email', this.resourcesForm.value)
      .subscribe((res) => {
        if (res.msg) {
          this.resourcesForm.reset();
          this.snackBar.open('Email sent successfully!!!', 'Dismiss');
        }
      });
  }
}
