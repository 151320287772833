import { Component, OnInit } from '@angular/core';
import { AppService } from '@strategic/shared/app-service';

@Component({
  selector: 'strategic-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.scss'],
})
export class WhoWeAreComponent implements OnInit {
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.scrollToTop();
  }
}
