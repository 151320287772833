import { Component, OnInit } from '@angular/core';
import {
  faChartLine,
  faMobileAndroid,
  faAppleWhole,
  faInfoCircle,
  faHandHoldingDollar,
  faBuilding,
  faHome,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'strategic-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  faAppleWhole = faAppleWhole as IconProp;
  faMobileAndroid = faMobileAndroid as IconProp;
  faChartLine = faChartLine as IconProp;
  faInfoCircle = faInfoCircle as IconProp;
  faDollarSign = faHandHoldingDollar as IconProp;
  faBuilding = faBuilding as IconProp;
  faHome = faHome as IconProp;
  faLock = faLock as IconProp;

  constructor() {}

  ngOnInit(): void {}
}
