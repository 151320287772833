import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@strategic/shared/app-service';

@Component({
  selector: 'strategic-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  toggleBool = true;
  constructor(private router: Router, private appService: AppService) {}

  ngOnInit(): void {
    // do something
  }

  public navigateTo(u: string) {
    this.router.navigate([`/${u}`]);
  }

  public toggleNav() {
    if (this.toggleBool) {
      this.appService.toggleNav$.next('out');
    } else {
      this.appService.toggleNav$.next('in');
    }
    this.toggleBool = !this.toggleBool;
  }
}
